import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';
import { VERIFICATION_TYPE } from '@/api/models/verification';
import { changePhone } from '@/api/client';

class PhoneChangeServiceVerificationClass extends VerificationControllerClass {
  data = { phone: '+7' };

  get getHiddenIdentity() {
    const numbersToHide = this.data.phone.substring(5, this.data.phone.length - 3);
    const hideMask = '*'.repeat(numbersToHide.length);

    return this.data.phone.replace(numbersToHide, hideMask);
  }

  initVerification() {
    return this.createVerification({
      identity: this.data.phone,
      type: VERIFICATION_TYPE.MOBILE_CONFIRMATION
    });
  }

  async submit() {
    if (!this.verificationStore?.isConfirmed) {
      return;
    }
    await changePhone({
      mobilePhone: this.data.phone,
      verificationId: this.verificationStore.verificationId
    });
    this.verificationStore.removeVerification();
  }
}
export const PhoneChangeService = new PhoneChangeServiceVerificationClass();
