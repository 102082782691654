
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PaymentCardAdd'
})
export default class extends Vue {
  @Prop({ default: false })
  loading = false;
}
