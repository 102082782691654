
import { Component, Prop, Vue } from 'vue-property-decorator';
import LmsSelect from '@/components/registration/FieldSelect.vue';
import { DisbursementCardData } from '@/api/models/disbursement';
import { CreditCardLogo } from '@/views/profile/Payment/PaymentCard/credit.card.values';
import { getCardTypeByMask } from '@/views/profile/Payment/PaymentCard/credit.card.tools';

@Component({
  name: 'PaymentCardPreview',
  components: { LmsSelect }
})
export default class extends Vue {
  @Prop({ required: true })
  card: DisbursementCardData;

  get mask(): string {
    return this.card.panMasked || '';
  }

  get holder(): string {
    return this.card.cardHolder || '';
  }

  get type(): string {
    return getCardTypeByMask(this.mask);
  }

  get logo() {
    return CreditCardLogo[this.type];
  }
}
