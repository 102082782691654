
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import LmsInput from '@/components/registration/FieldInput.vue';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import { numeric, required } from '@/components/registration/settings/rules';
import { LmsFieldState } from '@/components/registration/models/field';

const amount = {
  ref: 'testAmount',
  name: 'testAmount',
  settings: {
    tooltip: true,
    type: 'text',
    pattern: noSpacePattern,
    mask: '######'
  },
  rules: { required, numeric }
};
const fields = [amount];

@Component({
  name: 'PaymentCardCheckForm',
  components: { LmsInput }
})
export default class extends Vue {
  field = amount;

  @Prop({ required: true })
  private check!: (totalAmount: string) => Promise<void>;

  @Ref(amount.name)
  private input: LmsFieldState;

  fieldName = 'testAmount';
  loading = false;
  valid = false;

  error = '';

  get disabled() {
    return !this.valid;
  }

  handleInput() {
    if (this.input) {
      this.input.validate();
      this.valid = this.input.isValid;
    } else {
      this.valid = false;
    }
  }

  handleFocus() {
    this.error = '';
  }

  async onSubmit({ [this.fieldName]: testAmount }: Record<string, string>) {
    this.loading = true;
    try {
      await this.check(testAmount);
    } finally {
      this.loading = false;
    }
  }
}
