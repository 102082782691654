
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

const TIMER_TICK = 1000;

@Component({
  name: 'CircleTimer'
})
export default class CircleTimer extends Vue {
  @Prop({ required: true }) private value!: number;

  timeout = 0;

  get timeLeft() {
    return this.value;
  }

  set timeLeft(value: number) {
    this.input(value);
  }

  @Watch('timeLeft', { immediate: true })
  watchSeconds() {
    this.tick();
  }

  tick() {
    clearTimeout(this.timeout);
    if (this.timeLeft > 0) {
      this.timeout = setTimeout(() => {
        this.timeLeft--;
      }, TIMER_TICK);
    }
  }

  @Emit()
  input(seconds: number) {
    return seconds;
  }
}
