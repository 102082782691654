
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueMarkdown from 'vue-markdown';
import { TBankCardVerification } from '@/services/bankCard';
import PaymentCard from '@/views/profile/Payment/PaymentCard/PaymentCard.vue';
import PaymentCardAcceptForm from '@/views/profile/Payment/PaymentCard/approve/PaymentCardAcceptForm.vue';
import PaymentCardCheckForm from '@/views/profile/Payment/PaymentCard/approve/PaymentCardCheckForm.vue';

@Component({
  name: 'PaymentCardApprove',
  components: { PaymentCardCheckForm, PaymentCardAcceptForm, PaymentCard, VueMarkdown }
})
export default class extends Vue {
  @Prop({ default: '' })
  private error: string;

  @Prop({ required: true })
  private verification: TBankCardVerification;

  @Prop({ required: true })
  private check!: (totalAmount: string) => Promise<void>;

  @Prop({ required: false })
  private accept!: () => Promise<void>;

  get hasError(): boolean {
    return !!(this.error || this.isExpired);
  }

  get card() {
    return this.verification.response && this.verification.response.receiptStamp;
  }

  get checkRequired() {
    return this.card && !this.isConfirmed && !this.isExpired && 'testAmount' in this.card;
  }

  get status() {
    return this.verification.response && this.verification.response.state;
  }

  get dialogTitle() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_TITLE').toString();
  }

  get dialogContents() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_TEXT').toString();
  }

  get confirmButtonText() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_SUBMIT').toString();
  }

  get cancelButtonText() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_CANCEL').toString();
  }

  get isNew() {
    return this.verification.isNew;
  }

  get isConfirmed() {
    return this.verification.isConfirmed;
  }

  get isExpired() {
    return this.verification.isExpired;
  }

  get titleKey() {
    switch (true) {
      case this.isConfirmed:
        return 'PAYMENT_CARD.CONFIRMED.TITLE';
      default:
        return 'PAYMENT_CARD.APPROVE.TITLE';
    }
  }

  handleRemove() {
    this.$confirm(this.dialogContents, this.dialogTitle, {
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText,
      type: 'warning'
    }).then(() => {
      this.$emit('unlink');
      this.$notify.success(this.$t('NOTIFICATION.CARD_UNILNK.SUCCESS').toString());
    });
  }
}
