
import { Component, Mixins } from 'vue-property-decorator';
import { debtSettlementApplication } from '@/store/modules/debtSettlementApplication';
import { PROFILE_ROUTES } from '@/router/routes_type';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { Document } from '@/api/models/document';
import { DocumentService } from '@/services/document';
import { getDocument, getDocumentsList } from '@/api/document';
import i18n from '@/core/plugins/vue-i18n';
import { DebtSettlementApplication, DebtSettlementApplicationStatus } from '@/api/models/debtSettlementApplication';
import { DebtSettlementApplicationDocuments } from '@/views/profile/DebtSettlement/DebtSettlement';

const POOLING_INTERVAL = 10000;

@Component({ name: 'DebtSettlementListPage' })
export default class DebtSettlementListPage extends Mixins(CheckRoutingMixin) {
  private fetchApplicationsTimeout: null | number = 1;
  private loading: string[] = [];
  applications: DebtSettlementApplication[] = [];
  private applicationsDocuments: DebtSettlementApplicationDocuments[] = [];
  private filesDownloading: string[] = [];
  private opened: string[] = [];

  async mounted() {
    await this.initApplications();
  }

  async initApplications() {
    await debtSettlementApplication.initGetDebtSettlementApplications({ force: true });
    this.applications = debtSettlementApplication.getDebtSettlementApplications;

    if (this.fetchApplicationsTimeout) {
      clearTimeout(this.fetchApplicationsTimeout);
      this.fetchApplicationsTimeout = setTimeout(() => this.initApplications(), POOLING_INTERVAL);
    }
  }

  documentsRequested(status: DebtSettlementApplicationStatus) {
    return status === DebtSettlementApplicationStatus.DOCUMENTS_REQUESTED;
  }

  async navigateToDebtSettlement(id: string) {
    await this.redirect(PROFILE_ROUTES.EDIT_DEBT_SETTLEMENT_APPLICATION, { id });
  }

  async handleOpenApplicationTab(application: DebtSettlementApplication) {
    if (this.opened.includes(application.id)) {
      this.opened = this.opened.filter(id => id !== application.id);
    } else {
      this.opened.push(application.id);
      this.loading.push(application.id);
      await this.fetchDebtSettlementApplicationDocuments(application);
      setTimeout(() => (this.loading = this.loading.filter(a => a !== application.id)), 1000);
    }
  }

  tabLoading(id: string): boolean {
    return this.loading.includes(id);
  }

  statusTranslation(status: DebtSettlementApplicationStatus): string {
    return i18n.t(`DEBT_SETTLEMENT.STATUS.${status}`);
  }

  async fetchDebtSettlementApplicationDocuments(application: DebtSettlementApplication) {
    const initialDocuments = await Promise.all((application.payload.documentsList ?? []).map(getDocument));
    const { data: documentList } = await getDocumentsList({ metadata: { applicationId: application.id } });
    const applicationDocuments = this.applicationsDocuments.find(ad => ad.applicationId === application.id);
    documentList.push(...initialDocuments.map(({ data }) => data));

    if (applicationDocuments) {
      applicationDocuments.documentList = documentList;
    } else {
      this.applicationsDocuments.push({ applicationId: application.id, documentList });
    }
  }

  async download(documentId: string) {
    await this.handleDocument(documentId, () => DocumentService.download(documentId));
  }

  async handleDocument(documentId: string, action: () => void) {
    if (this.filesDownloading.includes(documentId)) {
      return;
    }

    this.filesDownloading.push(documentId);
    try {
      await action();
    } catch ({ response }) {
      this.$notify.error(this.$t('NOTIFICATION.DOCUMENT_UPLOAD.ERROR').toString());
    } finally {
      this.filesDownloading = this.filesDownloading.filter(file => file !== documentId);
    }
  }

  applicationDocuments(applicationId: string): Document[] {
    return this.applicationsDocuments.find(ad => ad.applicationId === applicationId)?.documentList ?? [];
  }

  beforeDestroy() {
    if (this.fetchApplicationsTimeout) {
      clearTimeout(this.fetchApplicationsTimeout);
      this.fetchApplicationsTimeout = null;
    }
  }
}
