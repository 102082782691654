
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { agreements } from '@/store/modules/agreements';
import { Agreement } from '@/api/models/agreements';
import DebtSettlementApplicationForm from '@/views/profile/DebtSettlement/DebtSettlementApplicationForm.vue';
import { PROFILE_ROUTES } from '@/router/routes_type';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { debtSettlementApplication } from '@/store/modules/debtSettlementApplication';
import { DebtSettlementApplication } from '@/api/models/debtSettlementApplication';

@Component({
  name: 'EditDebtSettlementApplicationPage',
  components: { DebtSettlementApplicationForm }
})
export default class extends Mixins(CheckRoutingMixin) {
  @Prop({ required: true }) id!: string;

  loading = true;
  debtSettlementApplication: DebtSettlementApplication | null = null;
  agreement: Agreement | null = null;

  async mounted() {
    await this.loadAgreementAmendmentApplication();
    await this.loadAgreement();
    this.loading = false;
  }

  async loadAgreementAmendmentApplication() {
    await debtSettlementApplication.initGetDebtSettlementApplications();
    this.debtSettlementApplication =
      debtSettlementApplication.getDebtSettlementApplications.find(a => a.id === this.id) ?? null;
  }

  async loadAgreement() {
    await agreements.initGetAgreementList();
    this.agreement =
      agreements.getAgreements.find(a => a.id === this.debtSettlementApplication?.payload?.loanId) ?? null;
  }

  async uploaded() {
    await this.redirect(PROFILE_ROUTES.DEBT_SETTLEMENT_LIST);
  }

  get formData() {
    return {
      appealText: this.debtSettlementApplication?.payload?.appealText ?? '',
      contactPhone: this.debtSettlementApplication?.payload?.contactPhone ?? ''
    };
  }

  async alreadyReviewed() {
    this.loading = false;
    await this.redirect(PROFILE_ROUTES.DEBT_SETTLEMENT_LIST);
  }
}
