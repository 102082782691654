import { createTransaction, executeTransaction } from '@/api/payment-gateway';
import { TransactionDetails } from '@/api/models/payment-gateway';

const cardPaymentProvider = 'processing_kz_one_click';

class Transaction {
  async create(details: TransactionDetails) {
    return createTransaction({
      provider: cardPaymentProvider,
      details
    });
  }

  execute(transactionId: string, amount: string) {
    return executeTransaction(transactionId, { amount });
  }
}

export const TransactionService = new Transaction();
