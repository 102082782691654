
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { client } from '@/store/modules/client';
import { DISBURSEMENT_TYPE, DisbursementCard } from '@/api/models/disbursement';
import PaymentCardSelect from '@/views/profile/Payment/PaymentCardSelect.vue';
import PaymentCardBody from '@/views/profile/Payment/PaymentCard/PaymentCardBody.vue';
import PaymentConfirmation from '@/views/profile/Payment/PaymentConfirm.vue';
import PaymentCardApprove from '@/views/profile/Payment/PaymentCard/PaymentCardApprove.vue';
import PaymentCardAddedSuccess from '@/views/profile/Payment/PaymentCard/PaymentCardAddedSuccess.vue';
import PaymentCardAdd from '@/views/profile/Payment/PaymentCard/PaymentCardAdd.vue';
import { invoiceTotals } from '@/api/lending';
import { TransactionService } from '@/services/transaction';
import { CURRENCY_SYMBOL } from '@/core/config/setup/product';
import { Agreement } from '@/api/models/agreements';

const awaitInterval = 3000;

@Component({
  name: 'MyLoanPayment',
  components: {
    PaymentCardAdd,
    PaymentCardAddedSuccess,

    PaymentCardApprove,
    PaymentConfirmation,

    PaymentCardBody,
    PaymentCardSelect
  }
})
export default class extends Vue {
  @Prop({ required: true })
  agreement: Agreement;
  @Prop({ default: () => false })
  confirmationPopup: boolean;

  currency = CURRENCY_SYMBOL;

  selecting = false;

  disbursement: DisbursementCard | null = client.bankCardSelected;

  transactionLoading = false;

  transactionId: string | null = null;

  amount = 0;

  get shadowed() {
    return Boolean(this.selecting);
  }

  get cards() {
    return client.disbursements[DISBURSEMENT_TYPE.BANK_CARD];
  }

  handleSelect(card: DisbursementCard) {
    this.disbursement = card;
  }

  mounted(): void {
    this.loadUnpaidAmount();
  }

  async requestTransaction() {
    if (this.transactionLoading) {
      return;
    }

    if (!this.disbursement) {
      return;
    }

    if (this.transactionId) {
      this.confirmation();

      return;
    }

    this.transactionLoading = true;
    const payload = { ...this.disbursement.data, paymentTarget: this.agreement.number };

    try {
      const { data } = await TransactionService.create(payload);
      this.transactionId = data.id;
      this.confirmation();
    } catch ({ data: { detail } }) {
      this.$notify.error(this.$t('PAYMENT_GATEWAY.TRANSACTION.ERROR').toString());
    } finally {
      this.transactionLoading = false;
    }
  }

  async submitPayment() {
    if (!this.transactionId || this.transactionLoading) {
      return;
    }

    this.transactionLoading = true;
    try {
      await TransactionService.execute(this.transactionId, this.amount.toString());
      this.$notify.success(this.$t('PAYMENT_GATEWAY.TRANSACTION.SUCCESS').toString());
    } catch ({ data: { detail } }) {
      this.$notify.error(this.$t('PAYMENT_GATEWAY.TRANSACTION.ERROR').toString());
    } finally {
      this.transactionLoading = false;
      this.transactionId = null;
      this.confirmation(false);
    }

    this.refreshUnpaidAmount();
  }

  @Emit('show-popup')
  confirmation(state = true) {
    return state ? this.agreement.id : '';
  }

  private toggleSelect(force: boolean | null = null) {
    this.selecting = force === null ? !this.selecting : force;
  }

  private openSelect() {
    this.toggleSelect(true);
  }

  private async loadUnpaidAmount() {
    const { data } = await invoiceTotals({ agreementId: this.agreement.id });
    if (!this.amount) {
      this.amount = Number(data.balance.unpaidAmount);
    }
  }

  private refreshUnpaidAmount(): void {
    this.amount = 0;
    // Wait a little for server to process the data (zero guarantee)
    setTimeout(this.loadUnpaidAmount, awaitInterval);
  }
}
