
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisbursementCardData } from '@/api/models/disbursement';
import PaymentCardPreview from '@/views/profile/Payment/PaymentCard/PaymentCardPreview.vue';

@Component({
  name: 'PaymentCardBody',
  components: { PaymentCardPreview }
})
export default class extends Vue {
  @Prop({ required: true })
  card: DisbursementCardData;

  @Prop({ required: false })
  label: string;

  hasClick() {
    return true;
  }
}
