import { CreditCardType } from '@/views/profile/Payment/PaymentCard/credit.card.values';

const MaskTest: Record<string, RegExp> = {
  [CreditCardType.AMEX]: /^3[47][0-9]{4}/,
  [CreditCardType.JSB]: /^(?:2131|1800|35\d{3})\d{1}/,
  [CreditCardType.VISA]: /^4[0-9]{5}/,
  [CreditCardType.MC]: /^(5[1-5][0-9]{4}|2(22[1-9][0-9]{2}|2[3-9][0-9]{4}|[3-6][0-9]{5}|7[0-1][0-9]{4}|720[0-9]{3}))/,
  [CreditCardType.DCLUB]: /^3(?:0[0-5]|[68][0-9])[0-9]{2}/,
  [CreditCardType.DIS]: /^65[4-9][0-9]{3}|64[4-9][0-9]{3}|6011[0-9]{2}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{1})/
};

export const getCardTypeByMask = (mask = '') => {
  return (
    Object.keys(MaskTest).find(key => {
      return MaskTest[key].test(mask);
    }) || CreditCardType.UNKNOWN
  );
};
