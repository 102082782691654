
import { Vue, Component, Emit, Ref } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsCheckboxGroup from '@/components/registration/FieldCheckBoxGroup.vue';
import { required, numeric, FORM_ERRORS } from '@/components/registration/settings/rules';
import { InputSetting } from '@/components/registration/models/input';
import { emailVerificationMask } from '@/components/registration/settings/mask';
import { LmsFormState } from '@/components/registration/models/form';
import LmsModal from '@/components/layout/modal.vue';
import { VERIFICATION_CONFIRM_STATUS } from '@/api/models/verification';
import CircleTimer from '@/components/CircleTimer.vue';
import { EmailChangeService } from '@/services/emailChange';

@Component({
  components: {
    CircleTimer,
    LmsModal,
    LmsForm,
    LmsInput,
    LmsCheckboxGroup
  }
})
export default class VerificationPopup extends Vue {
  @Ref('form') form: LmsFormState;

  codeRules = { required, numeric };
  codeSetting: InputSetting = {
    tooltip: true,
    placeholder: false,
    title: true,
    mask: emailVerificationMask
  };
  countDownTime = 0;
  loading = false;

  mounted() {
    this.startTimer();
  }

  get isRepeatActionActive() {
    return !this.loading && this.countDownTime === 0;
  }

  async repeatVerification() {
    if (this.countDownTime || this.loading || !EmailChangeService.verificationStore) {
      return;
    }
    this.loading = true;

    try {
      await EmailChangeService.verificationStore.repeat();
      await this.startTimer();

      this.codeSent();
    } catch (e) {
      return;
    } finally {
      this.loading = false;
    }
  }

  async startTimer() {
    if (!EmailChangeService.verificationStore) {
      return;
    }
    this.countDownTime = await EmailChangeService.verificationStore.getTimer();
  }

  async submit(payload: { emailCode: string }) {
    if (!EmailChangeService.verificationStore) {
      return;
    }
    this.loading = true;
    try {
      await EmailChangeService.verificationStore.confirm(payload.emailCode);
      this.confirm();
    } catch ({ data, status }) {
      if (status === VERIFICATION_CONFIRM_STATUS.INVALID_VERIFICATION_CODE) {
        return this.form.setErrors([
          {
            parameters: {},
            propertyPath: 'emailCode',
            title: FORM_ERRORS.FIELD_ERROR_WRONG_VERIFICATION_CODE
          }
        ]);
      }
      if (status === VERIFICATION_CONFIRM_STATUS.IS_ALREADY_CONFIRMED_OR_EXPIRED) {
        await EmailChangeService.verificationStore.registerVerification();
        await this.startTimer();
        this.codeSent();
        return;
      }
      return this.form.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  close(): string {
    return '';
  }

  codeSent() {
    this.$notify.success(this.$t('EMAIL_SETTINGS.POPUP_SMS_CODE_SEND_AGAIN').toString());
  }

  @Emit()
  confirm() {
    return;
  }
}
