
import { Component, Ref, Mixins } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { InputSetting } from '@/components/registration/models/input';
import { phoneNumberFormat, required } from '@/components/registration/settings/rules';
import { LmsFormState } from '@/components/registration/models/form';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import VerificationPopup from './VerificationPopup.vue';
import { mobilePhoneMask } from '@/components/registration/settings/mask';
import VerificationPopupMixin from '@/mixins/VerificationPopup.vue';
import { PhoneChangeService } from '@/services/phoneChange';

@Component({
  components: { VerificationPopup, LmsInput, LmsForm }
})
export default class ChangePasswordPage extends Mixins(VerificationPopupMixin) {
  @Ref() form!: LmsFormState;

  fieldSettings: InputSetting = {
    tooltip: false,
    type: 'text',
    mask: mobilePhoneMask,
    pattern: noSpacePattern
  };

  fieldRules = { required, phoneNumberFormat };

  formPayload = { phone: PhoneChangeService.data.phone };

  loading = false;

  async submit(payload: { mobilePhone: string }) {
    this.loading = true;

    PhoneChangeService.data.phone = payload.mobilePhone;

    try {
      await PhoneChangeService.initVerification();
      this.showVerificationPopupToggle = true;
    } catch ({ data }) {
      this.form.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }

  async verificationConfirmHandler() {
    if (!PhoneChangeService.verificationStore) {
      return;
    }
    try {
      await PhoneChangeService.submit();
      this.$notify.success(this.$t('NOTIFICATION.PHONE_CHANGE.SUCCESS').toString());
    } catch ({ data }) {
      this.form.setErrors(data.violations);
      this.$notify.error(this.$t('NOTIFICATION.PHONE_CHANGE.ERROR').toString());
    } finally {
      this.stopVerification();
    }
  }
}
