
import { ResponseList } from '@/api/client';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisbursementCard } from '@/api/models/disbursement';
import PaymentCard from '@/views/profile/Payment/PaymentCard/PaymentCard.vue';
import PaymentCardAdd from '@/views/profile/Payment/PaymentCard/PaymentCardAdd.vue';

@Component({
  name: 'PaymentCardSelect',
  components: { PaymentCardAdd, PaymentCard }
})
export default class extends Vue {
  @Prop({ required: false })
  selected: DisbursementCard;

  @Prop({ required: true })
  cards: ResponseList<DisbursementCard>;

  @Prop({ required: true })
  busy = false;

  @Prop()
  expanded = false;
}
