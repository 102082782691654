import { render, staticRenderFns } from "./DebtSettlementListPage.vue?vue&type=template&id=14227d51&scoped=true"
import script from "./DebtSettlementListPage.vue?vue&type=script&lang=ts"
export * from "./DebtSettlementListPage.vue?vue&type=script&lang=ts"
import style0 from "./DebtSettlementListPage.vue?vue&type=style&index=0&id=14227d51&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14227d51",
  null
  
)

export default component.exports