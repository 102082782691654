import { VerificationControllerClass } from '@/services/classes/verificationControllerClass';
import { VERIFICATION_TYPE } from '@/api/models/verification';
import { changeEmail } from '@/api/client';

class EmailChangeVerificationClass extends VerificationControllerClass {
  data = { email: '' };

  initVerification() {
    return this.createVerification({
      identity: this.data.email,
      type: VERIFICATION_TYPE.EMAIL_CONFIRMATION
    });
  }

  async emailChange() {
    if (!this.verificationStore?.isConfirmed) {
      return;
    }
    await changeEmail({
      email: this.data.email,
      verificationId: this.verificationStore.verificationId
    });
    this.verificationStore.removeVerification();
  }
}

export const EmailChangeService = new EmailChangeVerificationClass();
