
import { Component, Prop, Vue } from 'vue-property-decorator';
import LmsModal from '@/components/layout/modal.vue';

@Component({
  name: 'PaymentConfirmation',
  components: {
    LmsModal
  }
})
export default class extends Vue {
  @Prop({ required: true })
  amount: number;

  @Prop()
  loading = false;

  get paymentAmount() {
    return this.$options.filters?.money(this.amount);
  }
}
