import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=4cc2ede1&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=ts"
export * from "./Profile.vue?vue&type=script&lang=ts"
import style0 from "./Profile.vue?vue&type=style&index=0&id=4cc2ede1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc2ede1",
  null
  
)

export default component.exports