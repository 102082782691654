
import { Component, Ref, Mixins } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { InputSetting } from '@/components/registration/models/input';
import { emailFormat, required } from '@/components/registration/settings/rules';
import { LmsFormState } from '@/components/registration/models/form';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import VerificationPopup from './VerificationPopup.vue';
import VerificationPopupMixin from '@/mixins/VerificationPopup.vue';
import { EmailChangeService } from '@/services/emailChange';

@Component({
  components: { VerificationPopup, LmsInput, LmsForm }
})
export default class ChangeEmailPage extends Mixins(VerificationPopupMixin) {
  @Ref() form!: LmsFormState;

  fieldSettings: InputSetting = {
    tooltip: false,
    type: 'text',
    pattern: noSpacePattern
  };

  fieldRules = { required, emailFormat };

  formPayload = { email: EmailChangeService.data.email };

  loading = false;

  async submit(payload: { newEmail: string }) {
    this.loading = true;

    EmailChangeService.data.email = payload.newEmail;

    try {
      await EmailChangeService.initVerification();
      this.showVerificationPopupToggle = true;
    } catch ({ data }) {
      this.form.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }

  async verificationConfirmHandler() {
    if (!EmailChangeService.verificationStore) {
      return;
    }
    try {
      await EmailChangeService.emailChange();
      this.$notify.success(this.$t('NOTIFICATION.EMAIL_CHANGE.SUCCESS').toString());
    } catch (e) {
      this.$notify.error(this.$t('NOTIFICATION.EMAIL_CHANGE.ERROR').toString());
    } finally {
      this.stopVerification();
    }
  }
}
