
import { Component, Vue, Ref } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { ChangePasswordPayload } from '@/views/profile/ChangePassword/types';
import { InputSetting, LmsFieldInputState } from '@/components/registration/models/input';
import { FORM_ERRORS, passwordFormat, required } from '@/components/registration/settings/rules';
import { changePassword } from '@/api/client';
import { LmsFormState } from '@/components/registration/models/form';
@Component({
  components: { LmsInput, LmsForm }
})
export default class ChangePasswordPage extends Vue {
  @Ref() oldPasswordField!: LmsFieldInputState;

  @Ref() newPasswordField!: LmsFieldInputState;

  @Ref() repeatNewPasswordField!: LmsFieldInputState;

  @Ref() changePasswordForm!: LmsFormState;

  fieldSettings: InputSetting = {
    mask: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    type: 'password'
  };

  fieldRules = { required, passwordFormat };

  oldPassword = '';

  newPassword = '';

  repeatNewPassword = '';

  loading = false;

  newPasswordIsOld() {
    if (this.oldPassword === this.newPassword) {
      this.newPasswordField.error = FORM_ERRORS.FIELD_ERROR_NEW_PASSWORD_SAME_AS_OLD;
      return false;
    }

    return true;
  }

  repeatedPasswordCheck() {
    if (this.newPassword !== this.repeatNewPassword) {
      this.repeatNewPasswordField.error = FORM_ERRORS.FIELD_ERROR_PASSWORD_IS_NOT_SAME;
      return false;
    }

    return true;
  }

  resetForm() {
    this.oldPassword = '';
    this.newPassword = '';
    this.repeatNewPassword = '';
    this.oldPasswordField.value = '';
    this.oldPasswordField.masked = '';
    this.newPasswordField.value = '';
    this.newPasswordField.masked = '';
    this.repeatNewPasswordField.value = '';
    this.repeatNewPasswordField.masked = '';
  }

  get submitDisabled() {
    return Boolean(!this.newPassword || !this.repeatNewPassword || !this.oldPassword);
  }

  async submit(payload: ChangePasswordPayload) {
    if (!this.newPasswordIsOld() && !this.repeatedPasswordCheck()) {
      return;
    }

    this.loading = true;

    try {
      await changePassword(payload);
      this.resetForm();
      this.$notify.success({
        title: this.$t('NOTIFICATION.PASSWORD_CHANGED.TITLE').toString(),
        message: this.$t('NOTIFICATION.PASSWORD_CHANGED.MESSAGE').toString()
      });
    } catch ({ data }) {
      this.changePasswordForm.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }
}
