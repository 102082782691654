export enum CreditCardType {
  UNKNOWN = 'unknown',
  AMEX = 'amex',
  DCLUB = 'dinners-club',
  DIS = 'discover',
  JSB = 'jsb',
  MC = 'mastercard',
  VISA = 'visa'
}

export const CreditCardLogo: Record<string, CreditCardType> = {
  [CreditCardType.UNKNOWN]: require('@/assets/img/icons/cards/unknown.svg'),
  [CreditCardType.AMEX]: require('@/assets/img/icons/cards/amex.svg'),
  [CreditCardType.DCLUB]: require('@/assets/img/icons/cards/diners-club.svg'),
  [CreditCardType.DIS]: require('@/assets/img/icons/cards/discover.svg'),
  [CreditCardType.JSB]: require('@/assets/img/icons/cards/jcb.svg'),
  [CreditCardType.MC]: require('@/assets/img/icons/cards/mastercard.svg'),
  [CreditCardType.VISA]: require('@/assets/img/icons/cards/visa.svg')
};
