
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisbursementCardData } from '@/api/models/disbursement';
import PaymentCardBody from '@/views/profile/Payment/PaymentCard/PaymentCardBody.vue';

@Component({
  name: 'PaymentCard',
  components: { PaymentCardBody }
})
export default class extends Vue {
  @Prop({ required: true })
  card: DisbursementCardData;

  @Prop({ required: false })
  label: string;

  @Prop({ default: false })
  active: boolean;

  @Prop({ default: false })
  error: boolean;
}
