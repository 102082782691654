var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('lms-form',{ref:"form",on:{"submit":_vm.submit}},[_c('lms-input',{attrs:{"name":"appealText","setting":{ tooltip: true, type: 'textarea', rows: 5, disabled: _vm.onlyUpdateDocuments },"rules":{ required: _vm.required, longText: _vm.longText, shortText: _vm.shortText },"initial":_vm.appealText},on:{"input":val => (_vm.appealText = val)}}),_c('lms-input',{attrs:{"name":"contactPhone","setting":{
      tooltip: true,
      type: 'text',
      mask: _vm.mobilePhoneMask,
      pattern: _vm.noSpacePattern,
      disabled: _vm.onlyUpdateDocuments
    },"rules":{ required: _vm.required, phoneNumberFormat: _vm.phoneNumberFormat },"initial":_vm.contactPhone,"autocomplete":"tel"},on:{"input":val => (_vm.contactPhone = val)}}),_c('h4',{staticClass:"documents-title"},[_vm._v(" "+_vm._s(_vm.$t('DEBT_SETTLEMENT.DOCUMENTS_TITLE'))+" ")]),_c('lms-multi-input',{attrs:{"name":"files","max-items":5,"placeholder":_vm.$t('DEBT_SETTLEMENT.DOCUMENTS_PLACEHOLDER'),"tooltip":_vm.$t('DEBT_SETTLEMENT.DOCUMENTS_TOOLTIP')},on:{"input":_vm.upload}}),(_vm.onlyUpdateDocuments)?_c('div',{staticClass:"upload"},[_c('el-button',{staticClass:"application__button",attrs:{"loading":_vm.loading,"disabled":_vm.filesEquals,"type":"primary"},on:{"click":_vm.uploadFiles}},[_vm._v(" "+_vm._s(_vm.$t('FORM.UPLOAD_FILES'))+" ")])],1):_c('div',{staticClass:"submit"},[_c('el-button',{staticClass:"application__button",attrs:{"loading":_vm.loading,"type":"primary","native-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('DEBT_SETTLEMENT.SUBMIT_APPLICATION'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }