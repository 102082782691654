
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PaymentCardAcceptForm'
})
export default class extends Vue {
  @Prop({ required: true })
  private accept!: () => Promise<void>;

  error = '';
  loading = false;

  async onSave() {
    this.loading = true;
    try {
      await this.accept();
    } catch ({ data }) {
      this.error = data;
    } finally {
      this.loading = false;
    }
  }
}
