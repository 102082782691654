
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { agreements } from '@/store/modules/agreements';
import { Agreement } from '@/api/models/agreements';
import DebtSettlementApplicationForm from '@/views/profile/DebtSettlement/DebtSettlementApplicationForm.vue';
import { PROFILE_ROUTES } from '@/router/routes_type';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { DebtSettlementFormData } from '@/views/profile/DebtSettlement/DebtSettlement';

@Component({
  name: 'CreateDebtSettlementApplicationPage',
  components: { DebtSettlementApplicationForm }
})
export default class extends Mixins(CheckRoutingMixin) {
  @Prop({ required: true }) agreementId!: string;

  loading = true;
  agreement: Agreement | null = null;

  formData: DebtSettlementFormData = {
    appealText: '',
    contactPhone: '+7'
  };

  async mounted() {
    await this.loadAgreement();
    this.loading = false;
  }

  async loadAgreement() {
    await agreements.initGetAgreementList();
    this.agreement = agreements.getAgreements.find(a => a.id === this.agreementId) ?? null;
  }

  async submit() {
    await this.redirect(PROFILE_ROUTES.DEBT_SETTLEMENT_LIST);
  }

  async applicationExist() {
    this.loading = false;
    await this.redirect(PROFILE_ROUTES.DEBT_SETTLEMENT_LIST);
  }
}
