
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class VerificationPopupMixin extends Vue {
  showVerificationPopupToggle = false;

  get showVerificationPopup() {
    return this.showVerificationPopupToggle;
  }

  stopVerification() {
    this.showVerificationPopupToggle = false;
  }
}
