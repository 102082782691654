
import { Component, Ref, Vue } from 'vue-property-decorator';
import { LmsFormState } from '@/components/registration/models/form';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsSelect from '@/components/registration/FieldSelect.vue';
import { required } from '@/components/registration/settings/rules';
import { CONTENT_LOCALES } from '@/api/content';
import i18n from '@/core/plugins/vue-i18n';
import { changeLocale } from '@/api/client';
import { client } from '@/store/modules/client';

@Component({
  name: 'ChangeLocalePage',
  components: { LmsSelect, LmsInput, LmsForm },
  methods: { required }
})
export default class ChangeLocale extends Vue {
  @Ref() form!: LmsFormState;
  loading = false;
  locale: CONTENT_LOCALES = client.profile.locale;

  get locales() {
    return () => [
      { label: i18n.t('LANGUAGES.OPTIONS.KK'), value: CONTENT_LOCALES.kk },
      { label: i18n.t('LANGUAGES.OPTIONS.RU'), value: CONTENT_LOCALES.ru }
    ];
  }

  get isPreviousLocale() {
    return this.locale === client.profile.locale;
  }

  get language() {
    return this.locale === CONTENT_LOCALES.kk ? i18n.t('LANGUAGES.OPTIONS.KK') : i18n.t('LANGUAGES.OPTIONS.RU');
  }

  setLocale(locale: CONTENT_LOCALES) {
    this.locale = locale;
  }

  async submit(payload: { locale: CONTENT_LOCALES }) {
    this.loading = true;

    try {
      await changeLocale(payload);
      await client.initProfile({ force: true });

      this.$notify.success({
        title: this.$t('NOTIFICATION.LOCALE_CHANGED.TITLE').toString(),
        message: this.$t('NOTIFICATION.LOCALE_CHANGED.MESSAGE', { language: this.language }).toString()
      });
    } catch ({ data }) {
      this.form.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }
}
